<template>
	<div id="body">
		<!-- 通用banner布局-->
		<div id="banner">
			<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Fzbanner.jpg" >
		</div>
		<!-- PC页面布局 -->
		<div id="title">
			<div id="tit_mian">
				<div id="left">
					<span>云计算</span>
					<span>云存储</span>
					<span>跨平台</span>
				</div>
				<div id="right">
					  同时支持电脑端 手机APP 同步使用 实时连接用户的新一代智能进销存管理系统！
				</div>
			</div>
		</div>
		<div id="list">
			<div id="list_main">
				<p>大总管服装布料管理系统全面覆盖以下行业</p>
				<div id="list_main_main">
					<div id="flex_box">
						服装原材料行业
					</div>
					<div id="flex_box">
						皮革原材料行业
					</div>
					<div id="flex_box">
						布料行业
					</div>
					<div id="flex_box">
						鞋帽原材料行业
					</div>
					<div id="flex_box">
						箱包原材料行业
					</div>
					<div id="flex_box">
						玩具布料行业
					</div>
				</div>
			</div>
		</div>
		<div id="whatbox">
			<div id="whatbox_main">
				<p>什么是大总管服装布料管理系统</p>
				<div id="whatbox_main_box">
					<div id="whatbox_main_box_flex">
						<div id="tt">
							是一款针对布料皮革原材料的</br>高效库存管理工具
						</div>
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/TimesouOA.png" >
					</div>
					<div id="whatbox_main_box_flex">
						<div id="tt">
							是一套集采购、销售、</br>
							库存、供应商、客户与一体的</br>
							功能强大的管理系统
						</div>
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/TimesouOA2.png" >
					</div>
					<div id="whatbox_main_box_flex">
						<div id="tt">
							是一个集手机应用、PC应用</br>采用与云技术相结合的</br>智能移动管理平台
						</div>
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Timgshop.png" >
					</div>
				</div>
			</div>
		</div>
		<div id="funbox">
			<div id="bgc">
				<span style="display: block;font-size: 35px; color: #fd3f31;">大总管皮革布料进销存有什么功能模块？</span>
				<span style=" color: #fd3f31;">十大功能模块，满足不同场景对公司的管理需求</span>
			</div>
			<div id="bott">
				<div id="bott_main">
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='caigou1'></icon-svg>
						<p>采购订单管理</p>
					</div>
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='ruku'></icon-svg>
						<p>采购入库单管理</p>
					</div>
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='xiaoshoudingdan'></icon-svg>
						<p>销售订单管理</p>
					</div>
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='chuku'></icon-svg>
						<p>销售出库单管理</p>
					</div>
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='shoukuan'></icon-svg>
						<p>收款单管理</p>
					</div>
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='fukuandanguanli'></icon-svg>
						<p>付款单管理</p>
					</div>
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='wj-zcjl'></icon-svg>
						<p>费用支出管理</p>
					</div>
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='icon-p_dangqiankucun'></icon-svg>
						<p>库存报表管理</p>
					</div>
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='fq_yingfukuan'></icon-svg>
						<p>应付帐款管理</p>
					</div>
					<div id="icobox">
						<icon-svg class='iconclas' iconClass='shoukuandan'></icon-svg>
						<p>应收帐款管理</p>
					</div>
				</div>
			</div>
		</div>
		<div id="flowchart">
			<div id="flowbox">
				<span>
					大总管皮革布料进销存流程示意图
				</span>
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/flow.jpg" >
			</div>
		</div>
		<div id="issbox">
			<div id="issbox_main">
				<div id="issbox_tit">
					<p>大总管皮革布料进销存能解决什么问题?</p>
					<span>仓储采购销售库存中存在的问题轻松解决!</span>
				</div>
				<div id="issbox_main_box">
					<div id="issbox_main_box_left">
						<div id="title_le">
							企业问题
						</div>
						<div id="title_main">
							<span><span style="color: #fd3f31;">【采购管理难】</span>选哪个供应商？前期采购价格？</span>
						</div>
						<div id="title_main">
							<span><span style="color: #fd3f31;">【销售管理难】</span>销售价格不统一，按什么价格销售？</span>
						</div>
						<div id="title_main">
							<span><span style="color: #fd3f31;">【库存管理难】</span>商品是否该进？商品能否跟踪？</span>
						</div>
						<div id="title_main">
							<span><span style="color: #fd3f31;">【收付账款难】</span>供应商什么时候该付款？</span>
						</div>
						<div id="title_main">
							<span><span style="color: #fd3f31;">【报表汇总难】</span>能否批量给供应商对帐？给客户对帐？</span>
						</div>
					</div>
					<div id="issbox_main_box_right">
						<div id="title_ri">
							解决方案
						</div>
						<div id="title_ri_mian">
							<p style="font-size: 22px;">重要数据实时查询，库存数量自动预警</p>
							<span style="display:block;width: 80%;margin-left: 10%;"> 重要的客户销售单价能自动跟踪，库存商品数量能自动预警，应收应付帐款可实时查询汇总，让您对应收应付款项一目了然。</span>
						</div>
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/phone.jpg" >
					</div>
				</div>
			</div>
		</div>
		<div id="bottom">
			<div id="bottom_bgi">
				<div id="bottop">
					<p style="font-size: 40px;">大总管皮革布料进销存能给我带来什么好处?</p>
					<p>提升企业的效率，增强企业的竟争力</p>
				</div>
				<div id="bottom_flex">
					<div id="flex_box_bott">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/is01.png" >
						<p>能在采购中清楚的记录下材料的颜色、尺码、风格等属性方便对入库和订单的管理</p>
					</div>
					<div id="flex_box_bott">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/is02.png" >
						<p>销售从订单到出库，可根据商品的批次号实现精准跟踪，客户价格智能跟踪</p>
					</div>
					<div id="flex_box_bott">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/is03.png" >
						<p>可通过手机、电脑根据商品的条码、颜色、尺码、风格等属性快速查询</p>
					</div>
					<div id="flex_box_bott">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/is04.png" >
						<p>对应收应付款项，实时掌握，对供应商、客户实现精准对帐</p>
					</div>
				</div>
			</div>
		</div>
		<div id="fobox">
			<p style="font-size: 35px;">大总管，让管理更轻松</p>
			<p>不止是专业，更是对客户的量身订制，创新的数字化管理平台，打破传统的开发方式</p>
		</div>
		<!-- 手机官网页面 -->
		<div id="Phone_jxc_tit_Title_box">
			<p style="font-size: 25px;">云计算 云存储 跨平台</p>
			<p style="color: #999;">同时支持电脑端 手机APP 同步使用 实时连接用户的新一代智能进销存管理系统！</p>
		</div>
		<div id="Phone_jxc_tit_img">
			<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Phone_tit_img.jpg" >
			<p>电脑+手机APP 同步使用</p>
		</div>
		<div id="Phone_jxc_tit_btn">
			<p @click="phone_call"><i class="el-icon-phone-outline"></i>拨打电话</p>
		</div>
		<div id="Phone_Hy_box">
			<p>服装布料管理系统全面覆盖以下行业</p>
			<div id="Phone_Hy_box_flex">
				<div id="Phone_Hy_box_flex_box">
					<icon-svg class='iconclas' iconClass='yuanliao'></icon-svg>
					<p>服装原材料行业</p>
				</div>
				<div id="Phone_Hy_box_flex_box">
					<icon-svg class='iconclas' iconClass='pige'></icon-svg>
					<p>皮革原材料行业</p>
				</div>
				<div id="Phone_Hy_box_flex_box">
					<icon-svg class='iconclas' iconClass='buliao'></icon-svg>
					<p>布料行业</p>
				</div>
				<div id="Phone_Hy_box_flex_box">
					<icon-svg class='iconclas' iconClass='fuzhuang-duanqun'></icon-svg>
					<p>鞋帽原材料行业</p>
				</div>
				<div id="Phone_Hy_box_flex_box">
					<icon-svg class='iconclas' iconClass='xiangbao'></icon-svg>
					<p>箱包原材料行业</p>
				</div>
				<div id="Phone_Hy_box_flex_box">
					<icon-svg class='iconclas' iconClass='wanju'></icon-svg>
					<p>玩具布料行业</p>
				</div>
			</div>
		</div>
		<div id="Phone_what_jxc_box">
			<p>什么是大总管服装布料管理系统</p>
			<div id="Phone_what_jxc_box_flex">
				<div id="Phone_what_jxc_box_flex_box">
					<p>是一款针对布料皮革原材料的高效库存管理工具</p>
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/TimesouOA.png" >
				</div>
				<div id="Phone_what_jxc_box_flex_box">
					<p>是一套集采购、销售、库存、供应商、客户与一体的功能强大的管理系统</p>
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/TimesouOA2.png" >
				</div>
				<div id="Phone_what_jxc_box_flex_box">
					<p>是一个集手机应用、PC应用采用与云技术相结合的智能移动管理平台</p>
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Timgshop.png" >
				</div>
			</div>
		</div>
		<div id="Phone_mode_box">
			<div id="phone_title">
				<p id="first_child">皮革布料进销存有什么功能模块</p>
				<p>十大功能模块，满足不同场景对公司的管理需求</p>
			</div>
			<div id="Phone_mode_box_flex">
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='caigou1'></icon-svg>
					<p>采购订单管理</p>
				</div>
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='ruku'></icon-svg>
					<p>采购入库单管理</p>
				</div>
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='xiaoshoudingdan'></icon-svg>
					<p>销售订单管理</p>
				</div>
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='chuku'></icon-svg>
					<p>销售出库单管理</p>
				</div>
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='shoukuan'></icon-svg>
					<p>收款单管理</p>
				</div>
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='fukuandanguanli'></icon-svg>
					<p>付款单管理</p>
				</div>
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='wj-zcjl'></icon-svg>
					<p>费用支出管理</p>
				</div>
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='icon-p_dangqiankucun'></icon-svg>
					<p>库存报表管理</p>
				</div>
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='fq_yingfukuan'></icon-svg>
					<p>应付帐款管理</p>
				</div>
				<div id="Phone_mode_box_flex_box">
					<icon-svg class='iconclas' iconClass='shoukuandan'></icon-svg>
					<p>应收帐款管理</p>
				</div>
			</div>
		</div>
		<div id="Phone_flow_img">
			<div id="Phone_flow_tit">
				大总管皮革布料进销存流程示意图
			</div>
			<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Phoneflow.jpg" >
		</div>
		<div id="Phone_issue">
			<div id="Phone_issue_header">
				企业问题
			</div>
			<div id="Phone_issue_main">
				<div id="Phone_issue_main_box">
					<span><span style="color: #fd3f31;">【采购管理难】</span>选哪个供应商？前期采购价格？</span>
				</div>
				<div id="Phone_issue_main_box">
					<span><span style="color: #fd3f31;">【销售管理难】</span>销售价格不统一，按什么价格销售？</span>
				</div>
				<div id="Phone_issue_main_box">
					<span><span style="color: #fd3f31;">【库存管理难】</span>商品是否该进？商品能否跟踪？</span>
				</div>
				<div id="Phone_issue_main_box">
					<span><span style="color: #fd3f31;">【收付账款难】</span>供应商什么时候该付款？</span>
				</div>
				<div id="Phone_issue_main_box">
					<span><span style="color: #fd3f31;">【报表汇总难】</span>能否批量给供应商对帐？给客户对帐？</span>
				</div>
			</div>
		</div>
		<div id="Phone_solve_box">
			<div id="Phone_solve_box_header">
				解决方案
			</div>
			<div id="Phone_solve_box_main">
				<div id="Phone_solve_box_main_tit">
					<p>重要数据实时查询，库存数量自动预警</p>
					
					<span>重要的客户销售单价能自动跟踪，库存商品数量能自动预警，应收应付帐款可实时查询汇总，让您对应收应付款项一目了然。</span>
				</div>
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Phone_pnone.jpg" >
			</div>
		</div>
		<div id="Phone_benefit_box">
			<div id="Phone_benefit_box_title">
				<p>皮革布料进销存能给我带来什么好处?</p>
				<span>提升企业的效率，增强企业的竟争力</span>
			</div>
			<div id="Phone_benefit_box_flex">
				<div id="Phone_benefit_box_flex_box">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/is01.png" >
					<p>能在采购中清楚的记录下材料的颜色、尺码、风格等属性方便对入库和订单的管理</p>
				</div>
				<div id="Phone_benefit_box_flex_box">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/is02.png" >
					<p>销售从订单到出库，可根据商品的批次号实现精准跟踪，客户价格智能跟踪</p>
				</div>
				<div id="Phone_benefit_box_flex_box">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/is03.png" >
					<p>可通过手机、电脑根据商品的条码、颜色、尺码、风格等属性快速查询</p>
				</div>
				<div id="Phone_benefit_box_flex_box">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/is04.png" >
					<p>对应收应付款项，实时掌握，对供应商、客户实现精准对帐</p>
				</div>
			</div>
		</div>
		<footers></footers>
	</div>
</template>

<script>
	import footers from '../FOOTER.vue'
	export default{
		components: {
			footers
		},
		data(){
			return{
				
			}
		},
		mounted() {
			
		},
		methods:{
			phone_call(){
				window.location.href = 'tel:4006182019' 
			}
		}
	}
</script>

<style lang="less" scoped>
	
	@media screen and (min-width: 720px) {//PC banner样式
		#banner{
			width: 100%;
			height: 700px;
			img{
				width: 100%;
				height: 100%;
			}
		}
	}
	
	@media screen and (max-width: 720px) {//手机 banner样式
		#banner{
			width: 100%;
			height: 200px;
			img{
				width: 100%;
				height: 100%;
			}
		}
	}
	@media screen and (min-width: 720px) {//PC banner样式
		#title{
			width: 100%;
			height: 170px;
			border-bottom: 1px solid #f3f3f3;
			box-shadow: 0px 8px 16px rgb(234 234 234);
			#tit_mian{
				width: 66%;
				height: 100%;
				margin-left: 17%;
				display: flex;
				justify-content: space-around;
				align-items: center;
				#left{
					width: 50%;
					span{
						margin-right: 3%;
						font-size: 30px;
					}
				}
				#right{
					color: #999;
				}
			}
		}
	}
	
	@media screen and (max-width: 720px) {//手机 banner样式
		#title{
			display: none;
		}
	}
	@media screen and (min-width: 720px) {//PC banner样式
		#list{
			width: 100%;
			height: 300px;	
			margin-top: 3%;
			#list_main{
				width: 80%;
				height: 100%;
				margin-left: 10%;
				text-align: center;
				padding-top: 2%;
				p{
					font-size: 30px;
					color: #fd3f31;
				}
				#list_main_main{
					width:100%;
					height: 80%;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					align-items: center;
					#flex_box{
						width: 33%;
						height: 40%;
						background-color: #FFF000;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 22px;
						background-color: #fd3f31;
						border-radius: 5px;
						color: #FFFFFF;
						box-shadow: 3px 2px 11px #e2d2d2;
					}
				}
			}
		}
	}
	
	@media screen and (max-width: 720px) {//手机 banner样式
		#list{
			display: none;
		}
	}
	@media screen and (min-width: 720px) {//PC banner样式
		#whatbox{
			width: 100%;
			height: 500px;
			background-color: #f5f8fb;
			margin-top: 3%;
			#whatbox_main{
				width: 50%;
				height: 100%;
				margin-left: 25%;
				text-align: center;
				padding-top: 2%;
				p{
					font-size: 30px;
				}
				#whatbox_main_box{
					width: 100%;
					height: 87%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					#whatbox_main_box_flex{
						width: 32%;
						height: 95%;
						border-top: 2px solid #fd3f31;
						padding-top: 3%;
						border-left: 1px solid #dcdcdc;
						border-right: 1px solid #dcdcdc;
						border-bottom: 1px solid #dcdcdc;
						box-shadow:2px 5px 5px #dcdcdc;
						transition: all .8s;
						#tt{
							width: 100%;
							height: 30%;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 20px;
						}
						img{
							height: 66%;
							max-width: 100%;
						}
					}
					#whatbox_main_box_flex:hover{
						width: 33%;
						height: 100%;
					}
				}
			}
		}
	}
	
	@media screen and (max-width: 720px) {//手机 banner样式
		#whatbox{
			display: none;
		}
	}
	@media screen and (min-width: 720px) {//PC banner样式
		#funbox{
			width: 100%;
			height: 800px;
			margin-top: 5%;
			#bgc{
				width: 100%;
				height: 50%;
				background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/funimgbgc.jpg');
				background-size: 100% 100%;
				text-align: center;
				padding-top: 4%;
			}
			#bott{
				height: 50%;
				position: relative;
				#bott_main{
					width: 70%;
					height: 130%;
					background-color: #FFFFFF;
					margin-left: 15%;
					position: absolute;
					top: -45%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;
					#icobox{
						width: 19%;
						height: 49%;
						text-align: center;
						padding-top: 5%;
						.iconclas{
							font-size: 80px;
							margin-bottom: 10%;
							color: #fd3f31;
						}
					}
				}
			}
			
		}
	}
	
	@media screen and (max-width: 720px) {//手机 banner样式
		#funbox{
			display: none;
		}
	}
	@media screen and (min-width: 720px) {//PC banner样式
		#flowchart{
			width: 100%;
			height: 600px;
			#flowbox{
				width: 80%;
				margin-left: 10%;
				height: 100%;
				text-align: center;
				padding-top: 2%;
				span{
					font-size: 32px;
					display: block;
				}
				img{
					max-width: 100%;
					max-height: 60%;
					margin-top: 3%;
				}
			}
		}
	}
	@media screen and (max-width: 720px) {//手机 banner样式
		#flowchart{
			display: none;
		}
	}
	@media screen and (min-width: 720px) {//手机 banner样式
		#issbox{
			width: 100%;
			height: 1000px;
			background-color: #f5f8fb;
			#issbox_main{
				width: 80%;
				height: 100%;
				margin-left: 10%;
				#issbox_tit{
					height: 15%;
					width: 100%;
					text-align: center;
					padding-top: 2%;
					p{
						font-size: 30px;
					}
				}
				#issbox_main_box{
					width: 80%;
					height: 71%;
					border: 1px solid #cccccc;
					margin-left: 10%;
					margin-top: 1%;
					display: flex;
					justify-content: center;
					align-items: center;
					#issbox_main_box_left{
						width: 40%;
						height: 100%;
						#title_le{
							background-color: #fd3f31;
							width: 100%;
							height: 15%;
							color: #FFFFFF;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 18px;
							border-right: 1px solid #FFFFFF;
						}
						#title_main{
							width: 100%;
							height: 17%;
							border-bottom: 1px solid #c2c2c2;
							display: flex;
							justify-content: flex-start;
							padding-left: 5%;
							align-items: center;
							font-size: 18px;
							border-right: 1px solid #c2c2c2;
							// background-color: #999;
						}
					}
					#issbox_main_box_right{
						width: 60%;
						height: 100%;
						text-align: center;
						overflow: hidden;
						#title_ri{
							background-color: #fd3f31;
							width: 100%;
							height: 15%;
							color: #FFFFFF;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 18px;
						}
						#title_ri_mian{
							width: 100%;
							height: 15%;
							text-align: center;
							margin-top: 2%;
						}
						img{
							max-width: 100%;
							height:70%;
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 720px) {//手机 banner样式
		#issbox{
			display: none;
		}
	}
	@media screen and (min-width: 720px) {//PC样式
		#bottom{
			width: 100%;
			height: 600px;
			background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/jxbgc.jpg');
			background-size: 100% 100%;
			#bottom_bgi{
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,0.7);
				#bottop{
					text-align: center;
					color: #FFFFFF;
					padding-top: 2%;
				}
				#bottom_flex{
					width: 80%;
					height: 50%;
					margin-top: 2%;
					margin-left: 10%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;
					#flex_box_bott{
					width:23%;
					height: 95%;
					background-color: #FFFFFF;
					text-align: center;
					transition: all .8s;
					padding-top: 2%;
					img{
						max-width: 100%;
						height: 70%;
					}
					p{
						width: 90%;
						margin-left: 5%;
					}
					}
					#flex_box_bott:hover{
						width:25%;
						height: 100%;
					}
				}
			}
		}
	}
	@media screen and (max-width: 720px) {//手机 banner样式
		#bottom{
			display: none;
		}
	}
	@media screen and (min-width: 720px) {//手机 banner样式
			#fobox{
				width: 100%;
				height: 150px;
				background-color: #2D82DD;
				text-align: center;
				padding-top: 1%;
				color: #FFFFFF; 	
			}
		}
	@media screen and (max-width: 720px) {//手机 banner样式
		#fobox{
			display: none;
		}
	}
	
	// 手机页面样式设计
	@media screen and (max-width: 720px) {//手机 banner样式
		#Phone_jxc_tit_img{
			width: 100%;
			height: 300px;
			text-align: center;
			margin-top: 5%;
			img{
				max-width: 100%;
				height: 80%;
			}
			p{
				height: 20%;
				display: flex;
				font-size: 22px;
				justify-content: center;
				align-items: center;
			}
		}
	}
	@media screen and (min-width: 720px) {//电脑样式
		#Phone_jxc_tit_img{
			display: none;
		}
	}
	@media screen and (max-width: 720px) {//手机样式
			#Phone_jxc_tit_btn{
				width: 100%;
				height: 60px;
				background-color: #fd3f31;
				color: #FFFFFF;
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				p{
					width: 50%;
					margin: 0;
				}
			}
		}
	@media screen and (min-width: 720px) {//电脑样式
		#Phone_jxc_tit_btn{
			display: none;
		}
	}
	@media screen and (max-width: 720px) {//手机样式
		#Phone_Hy_box{
			width: 100%;
			height: 400px;
			text-align: center;
			margin-top: 5%;
			p{
				width: 100%;
				font-size: 22px;
			}
			#Phone_Hy_box_flex{
				width: 100%;
				height: 87%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;
			}
			#Phone_Hy_box_flex_box{
				width: 49%;
				height: 32%;
				padding-top: 3%;
				.iconclas{
					font-size: 50px;
					margin-bottom: 2%;
					color: #fd3f31;
				}
				p{
					font-size: 20px;
					margin: 0;
					font-weight: bold;
				}
			}
		}
	}
	@media screen and (min-width: 720px) {//电脑样式
		#Phone_Hy_box{
			display: none;
		}
	}
	@media screen and (max-width: 720px) {//手机样式
		#Phone_what_jxc_box{
			width: 100%;
			height: 600px;
			text-align: center;
			margin-top: 20px;
			p{
				font-weight: bold;
				font-size: 22px;
			}
			#Phone_what_jxc_box_flex{
				width: 100%;
				height: 87%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				#Phone_what_jxc_box_flex_box{
					width: 49%;
					height: 49%;
					box-shadow: 2px 2px 10px #e2e2e2;
					border-radius: 5px;
					p{
						font-weight: normal;
						font-size: 17px;
						height: 40%;
						margin: 0;
					}
					img{
						max-width: 100%;
						height: 60%;
					}
				}
			}
		}
	}
	@media screen and (min-width: 720px) {//电脑样式
		#Phone_what_jxc_box{
			display: none;
		}
	}
	@media screen and (max-width: 720px) {//手机样式
		#Phone_mode_box{
			width: 100%;
			height: 1000px;
			#phone_title{
				width: 100%;
				height: 25%;
				text-align: center;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				align-content: center;
				color: #FFFFFF;
				background-color: #fd3d31;
				#first_child{
					font-size: 25px;
				}
			}
			#Phone_mode_box_flex{
				width: 100%;
				height: 75%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				#Phone_mode_box_flex_box{
					width: 50%;
					height: 20%;
					text-align: center;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					align-content: center;
					.iconclas{
						font-size: 60px;
						color: #fd3f31;
					}
					p{
						width: 100%;
						margin: 0;
						font-weight: bold;
					}
				}
			}
			
		}
	}
	@media screen and (min-width: 720px) {//电脑样式
		#Phone_mode_box{
			display: none;
		}
	}
	@media screen and (max-width: 720px) {//手机样式
		#Phone_flow_img{
			width: 100%;
			height: 500px;
			#Phone_flow_tit{
				width: 100%;
				height: 20%;
				text-align: center;
				font-size: 22px;
				font-weight: bold;
				background-color: #fd3f31;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #FFFFFF;
			}
			img{
				max-width: 100%;
				height: 80%;
			}
		}
	}
	@media screen and (min-width: 720px) {//电脑样式
		#Phone_flow_img{
			display: none;
		}
	}
	@media screen and (max-width: 720px) {//手机样式
		#Phone_issue{
			width: 100%;
			height: 500px;
			#Phone_issue_header{
				width: 100%;
				height: 15%;
				background-color: #fd3d31;
				color: #FFFFFF;
				font-size: 22px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			#Phone_issue_main{
				width: 100%;
				height: 85%;
				#Phone_issue_main_box{
					width: 100%;
					height: 20%;
					text-align: center;
					border-bottom: 1px solid #F2F2F2;
					display: flex;
					justify-content: flex-start;
					align-items: center;
				}
			}
		}
	}
	@media screen and (min-width: 720px) {//电脑样式
		#Phone_issue{
			display: none;
		}
	}
	@media screen and (max-width: 720px) {//手机样式
		#Phone_solve_box{
			width: 100%;
			height: 800px;
			#Phone_solve_box_header{
				width:100%;
				height: 12%;
				background-color: #fd3f31;
				color: #FFFFFF;
				font-size: 22px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			#Phone_solve_box_main{
				width: 100%;
				height: 88%;
				text-align: center;
				#Phone_solve_box_main_tit{
					text-align: center;
					padding-top: 10%;
					padding-left: 3%;
					padding-right: 3%;
					p{
						font-size: 20px;
						font-weight: bold;
					}
				}
				img{
					margin-top: 10%;
					max-width: 100%;
					height: 70%;
				}
			}
		}
	}
	@media screen and (min-width: 720px) {//电脑样式
		#Phone_solve_box{
			display: none;
		}
	}
	@media screen and (max-width: 720px) {//手机样式
		#Phone_benefit_box{
			width: 100%;
			height: 600px;
			margin-top: 5%;
			#Phone_benefit_box_title{
				width: 100%;
				height: 20%;
				background-color: #fd3f31;
				text-align: center;
				color: #FFFFFF;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				p{
					font-size: 22px;
				}
			}
			#Phone_benefit_box_flex{
				width: 100%;
				height: 79%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;
				#Phone_benefit_box_flex_box{
					width: 49%;
					height: 49%;
					text-align: center;
					box-shadow:2px 2px 10px #e2e2e2;
					padding-left: 3%;
					padding-right: 3%;
					img{
						height: 75%;
						max-width: 100%;
					}
					p{
						margin: 0;
						font-size: 13px;
					}
				}
			}
		}
	}
	@media screen and (min-width: 720px) {//电脑样式
		#Phone_benefit_box{
			display: none;
		}
	}
	@media screen and (max-width: 720px) {//手机样式
		#Phone_jxc_tit_Title_box{
			width: 100%;
			height: 100px;
			margin-top: 5%;
			text-align: center;
		}
	}
	@media screen and (min-width: 720px) {//电脑样式
		#Phone_jxc_tit_Title_box{
			display: none;
		}
	}
</style>
